//image imports
import jakey_icon from './img/jakey.webp';
import meme_icon from './img/meme.webp';

//jakey sounds
import balls_feel_romantical from './sounds/jakey/balls_feel_romantical.mp3';
import dawg from './sounds/jakey/dawg.mp3';
import dukerton from './sounds/jakey/dukerton.mp3';
import huh from './sounds/jakey/huh.mp3';
import if_you_wanna_kill_a_demon from './sounds/jakey/kill_a_demon.mp3';
import peace_and_war from './sounds/jakey/peace_and_war.mp3';
import royally_up_them_cheeks from './sounds/jakey/royally_cheeks.mp3';
import secret from './sounds/jakey/secret.mp3';
import what from './sounds/jakey/what.mp3';
import whorehouse from './sounds/jakey_2/whorehouse.mp3';
import trippin from './sounds/jakey_2/trippin.mp3'
import wonderful_nap from './sounds/jakey_2/wonderful_nap.mp3';
import hot_mouf from './sounds/jakey_2/hot_mouf.mp3'


// meme sounds
import mj_hee_hee from './sounds/meme/mj_hee_hee.mp3';
import vine_boom from './sounds/meme/vine_boom.mp3';
import vine_boom_loud from './sounds/meme/vine_boom_loud.mp3';
import spongebob_horn from './sounds/meme/spongebob_horn.mp3'
import spongebob_fail from './sounds/meme/spongebob_fail.mp3'
import scooby_laugh from './sounds/meme/scooby_laugh.mp3'
import nokia_ringtone from './sounds/meme/nokia_ringtone.mp3'
import nokia_ringtone_kick from './sounds/meme/nokia_ringtone_kick.mp3'
import nokia_ringtone_arabic from './sounds/meme/nokia_ringtone_arabic.mp3'
import sad_hampter from './sounds/meme/sad_hampter.mp3'
import cat_laugh from './sounds/meme/cat_laugh.mp3'
import cat_laugh_loud from './sounds/meme/cat_laugh_loud.mp3'


const data = [
        {
            id: 1,
            name: 'Jakey',
            image: jakey_icon,
            sounds: [balls_feel_romantical, if_you_wanna_kill_a_demon, dukerton, dawg, huh, what, peace_and_war, royally_up_them_cheeks, whorehouse, trippin, wonderful_nap, hot_mouf, secret],
            soundNames: ['Balls feel Romantical', 'If you wanna kill a demon...', 'Dukerton', 'DAWWWWGGG', 'huh', 'what', 'War and Peace', 'Up in them cheeks (proper)', 'Whorehouse is invaded', 'Now im tripping', 'Wonderful Nap', 'Kiss me on my hot Mouf', 'secret']
        },
        {
            id: 2,
            name: 'Random',
            image: meme_icon,
            sounds: [mj_hee_hee, vine_boom, vine_boom_loud, spongebob_horn, spongebob_fail, scooby_laugh, nokia_ringtone, nokia_ringtone_kick, nokia_ringtone_arabic, sad_hampter, cat_laugh, cat_laugh_loud],
            soundNames: ['Hee Hee - Michael Jackson', 'Vine Boom', 'Vine Boom [LOUD]', 'Spongebob Horn', 'Spongebob Fail', 'Scooby Doo Laugh', 'Nokia Ringtone', 'Nokia Kick Ringtone','Nokia Arabic Ringtone', 'Sad Hampter', 'Cat Laugh', 'Cat Laugh [LOUD]']
        },
]

export default data;