// App.js
import React, { useState } from 'react';
import logo from './img/logo.webp';
import data from './data'
import Character from './Components/Character';
import PasswordComponent from './Components/PasswordComponent';

function App() {
  const [passwordEntered, setPasswordEntered] = useState(false);
  const [characters] = useState(data);
  const [activeChar, setActiveChar] = useState(characters[0]);

  const handlePasswordSubmit = (password) => {
    if (password === process.env.REACT_APP_JAKEYSOUNDBOARD_PASSWORD) {
      setPasswordEntered(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="App">
      {!passwordEntered ? (
        <PasswordComponent onPasswordSubmit={handlePasswordSubmit} />
      ) : (
        <>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <ul className="characters-con">
            {characters.map((char) => {
              const { id, image } = char;
              return (
                <li
                  key={id}
                  className={`${activeChar.id === id ? 'active' : ''}`}
                  onClick={() => setActiveChar(char)}
                >
                  <div className="characters">
                    <img src={image} alt="" />
                  </div>
                </li>
              );
            })}
          </ul>
          <Character {...activeChar} />
        </>
      )}
    </div>
  );
}

export default App;
